<template>
  <CommonButton
    v-if="!emailSend"
    bg="bg-blue"
    text="text-white"
    border="border-none"
    @click="sendActivationEmail"
  >
    {{ $t('ResendActivationMail') }}
  </CommonButton>
  <div v-else class="h-9 leading-9">
    {{ $t('EmailSendt') }}
  </div>
</template>

<script setup lang="ts">
const { sendConfirmationEmail, sendConfirmationEmailJwt } = useEmailFlow()

const userStore = useUserStore()

const emailSend = ref(false)

async function sendActivationEmail() {
  if (!userStore.user) {
    return
  }

  const requestUrl = useRequestURL()

  if (userStore.user.userGuid) {
    const { err } = await sendConfirmationEmailJwt(
      requestUrl.href,
      userStore.user.email
    )

    if (err) {
      console.error('Error in sendConfirmationEmailJwt:', err.cause)
    } else {
      emailSend.value = true
    }
  } else {
    const { err } = await sendConfirmationEmail(
      `${requestUrl.href}?o=o`, // HACK! backend is not handling urls correctly have to add a ? to make it work
      userStore.user.userId,
      'standart'
    )

    if (err) {
      console.error('Error in resendActivationMail:', err.cause)
    } else {
      emailSend.value = true
    }
  }
}
</script>
